
.otherDetails{padding: 20px 0px;}
.otherDetails ul{display: flex;align-items: center;}
.otherDetails li{padding:0px 0px 0px 80px;width: 100%;border-left: 0.5px solid #666;display: flex;align-items: center;}
.otherDetails li:first-child{padding-left: 0;border: none;}
.otherDetails li figure{margin-right: 10px;width: 45px;height: 45px;}
.otherDetails li label{display: block;font-size: 20px;color: #000;font-weight: bold;}
.otherDetails li span{ color: #222;font-size: 12px;}
.otherDetails li img{object-fit: contain;width: 100%;height: 100%;}


@media screen and (max-width:768px) {
    .otherDetails {overflow: auto;}
    .otherDetails li{padding: 0px 15px 0px 15px;}
    .otherDetails li label{white-space: nowrap;}
    .otherDetails li span{white-space: nowrap;}
    
}

@media screen and (max-width:540px) {
    .otherDetails li {padding: 0;flex-direction: column;border: none;text-align: center;width: 100%;}
    .otherDetails li figure{margin: 0px auto 6px;width: 20px;height: inherit;}
    .otherDetails li label{font-size: 14px;}
    .otherDetails li span{font-size: 8px;}
    .otherDetails li div{display: contents;line-height: normal;}
}
